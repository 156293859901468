define('settings',[
    'module',
    'underscore',
    'cookies',
    'envHandler',
    'browserUtils'
], function (module, _, Cookies, EnvironmentHandler, BrowserUtils) {
    'use strict';

    var env = ' ';
    var lastcommit = '7e106b95553192f7e66a26a0791fe4381bb042b8';
    var version = '2.4.1-RC.0';
    var date = 1738167438426;
    var _default = {
        environment: env,
        lastcommit: lastcommit,
        version: version,
        date: date,
        perPage: 17,
        pageNumber: 1,
        application: 'dashboard',
        lang: BrowserUtils.getLanguage(),
        defaultLogo: EnvironmentHandler.getValue(env, 'defaultLogo'),
        obsolete: EnvironmentHandler.getValue(env, 'obsolete'),
        folder: EnvironmentHandler.getValue(env, 'folder')
    };

    var serverUrl = EnvironmentHandler.getValue(env, 'serverUrl');
    if (!serverUrl) {
        serverUrl = new URL(window.location.href).origin;
    }
    if (!serverUrl.endsWith('/')) {
        serverUrl += '/';
    }

    var Settings = {
        isDebugMode: true,
        tplEngine: 'html',
        storeInCookies: ['lastUrlPageCookie', 'lang', 'userFileExtension', 'loginTo'],
        storeInMemory: {},
        numbers: ['perPage', 'pageNumber'],
        serverUrl: serverUrl,

        set: function (name, value) {
            if (!name) {
                return;
            }
            if (name === 'sessionId' && !value) {
                return;
            } else if (_.contains(this.storeInCookies, name)) {
                if (!_.isEmpty(value) || value) {
                    value = value.toString();
                    var size = new Blob([value]).size;
                    if (size > 4096) {
                        alert('Cookie "' + name + '" is too big (' + size + ' bytes). Max size is 4096 bytes.');
                        new Error('Cookie "' + name + '" is too big (' + size + ' bytes). Max size is 4096 bytes.');
                    }
                    Cookies.set(name, value, {expires: 30, path: '/'});
                    this[name] = value;
                } else {
                    Cookies.remove(name);
                    delete this[name];
                }
            } else {
                this[name] = value;
            }
        },

        get: function (name) {
            var option;
            if (_.contains(this.storeInCookies, name)) {
                option = Cookies.get(name);
                if (option === 'false') {
                    option = false;
                }
            }
            option = option || _default[name];
            if (_.contains(this.numbers, name)) {
                option = parseInt(option, 10);
            }
            if (name === 'path' && option) {
                option = option.split(',');
            }
            if (this[name]) {
                option = this[name];
            }
            return option;
        },

        url: function (path, params) {
            var stringParams = '',
                ampersand = '';

            // check if relative url is an absolute url
            if (path.indexOf('http') === 0) {
                if (path.startsWith('/')) {
                    path = path.substring(1);
                }
                path = this.serverUrl + path;
            }

            params = params || {};

            if (path) {
                if (path.indexOf('?') === -1) {
                    stringParams = '?';
                } else {
                    stringParams = '&';
                }
            } else {
                stringParams = '?';
            }

            if (this.lang) {
                params.lang = this.lang;
            } else {
                params.lang = this.get('lang');
            }

            _.each(params, function (value, key) {
                if (value !== undefined && value !== null && key) {
                    if (key === 'poi' && typeof value === 'object') {
                        _.each(value, function (value) {
                            stringParams += (ampersand + key + '=' + value.toString());
                            ampersand = '&';
                        });
                    } else {
                        stringParams += (ampersand + key + '=' + value.toString());
                        ampersand = '&';
                    }
                }
            });

            return path + stringParams;
        },

        generateParamUrl: function (params) {
            var stringParams = '',
                ampersand = '';
            _.each(params, function (value, key) {
                if (value !== undefined && value !== null && key) {
                    if (key === 'poi' && typeof value === 'object') {
                        _.each(value, function (value) {
                            stringParams += (ampersand + key + '=' + value.toString());
                            ampersand = '&';
                        });
                    } else {
                        stringParams += (ampersand + key + '=' + value.toString());
                        ampersand = '&';
                    }
                }
            });
            return stringParams;
        },

        setToMemory: function (key, value) {
            value = JSON.stringify(value);

            if (key) {
                if (_.isEmpty(value) || !value) {
                    delete Settings.storeInMemory[key];
                } else {
                    Settings.storeInMemory[key] = value;
                }
            }
        },

        clearMemory: function () {
            Settings.storeInMemory = {};
        },

        getFromMemory: function (key) {
            var value = Settings.storeInMemory[key];
            if (value) {
                value = JSON.parse(value);
            }

            return value;
        },
        getDocUrl: function (docObject) {
            var url = this.url('rest/public/document/getfileByName/TB_' + docObject) + '#toolbar=0';
            return 'window.open(\'' + url + '\', \'documentation\', \'width=\' + window.outerWidth * 0.6 + \',height=\' + window.outerWidth * 0.7)';
        }
    };

    module.exports = Settings;
});

